import "./styles.css";
import React from 'react'
import Layout from '../src/components/Layout/Layout'
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <div className="App">
   <Layout/>
    </div>
  
   
  );
}

export default App;
